import { useCallback, useRef } from 'react'
import { DeviceHttpProxyPlugin, HttpRequest } from '../plugins/DeviceHttpProxy'
import { WhenJWebReady } from '@jarvis/jweb-core'
import { Logger } from '../utils/Logger'

const useDeviceHttpProxy = () => {
  const deviceHttpProxy = useRef<DeviceHttpProxyPlugin>(null)
  const wait = (ms) => new Promise((r) => setTimeout(r, ms))
  const sendDHPRequest = useCallback(
    async (options: HttpRequest) => {
      if (!deviceHttpProxy.current?.sendRequest) {
        return await wait(500).then(sendDHPRequest.bind(null, options))
      }
      try {
        return await deviceHttpProxy.current.sendRequest(options)
      } catch (e) {
        Logger.error(
          'Error on sending device proxy request',
          'options:',
          options,
          e
        )
      }
    },
    [deviceHttpProxy]
  )

  const initializeDHP = useCallback(async () => {
    const response = await WhenJWebReady
    deviceHttpProxy.current = response.Plugins
      .DeviceHttpProxy as DeviceHttpProxyPlugin
    if (!deviceHttpProxy.current) {
      Logger.error('JWeb plugin: DeviceHttpProxy not available')
    }
  }, [])

  return {
    initializeDHP,
    sendDHPRequest
  }
}

export default useDeviceHttpProxy
