import React from 'react'
import HardwareStatusCheckPage from './pages/HardwareStatusCheck/HardwareStatusCheckPage'
import ConfigProvider from './store/ConfigContext'
import LaunchDarkly from './store/LaunchDarkly'

const HardwareStatusCheck = (props) => {
  return (
    <LaunchDarkly clientId={props.ldClientId}>
      <ConfigProvider {...props}>
        <HardwareStatusCheckPage />
      </ConfigProvider>
    </LaunchDarkly>
  )
}

export default HardwareStatusCheck
