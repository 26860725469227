const APP_NAME = 'hardware-status-check'
const VERSION = process.env.APP_VERSION
const PREFIX = `${APP_NAME}:${VERSION}`

type SeverityType = 'log' | 'info' | 'error' | 'warn' | 'debug'

const SEVERITIES: SeverityType[] = ['log', 'info', 'error', 'warn', 'debug']

export const Logger = SEVERITIES.reduce(
  (logger, severity) => ({
    ...logger,
    [severity]: (...message: unknown[]) =>
      process.env.SHOULD_LOG && console[severity](PREFIX, ...message)
  }),
  {} as {
    [K in SeverityType]: (...message: unknown[]) => void
  }
)
