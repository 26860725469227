import React, { useState, useEffect, useMemo } from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import { createBrowserHistory } from 'history'
import {
  DataValveCDMEvent,
  PublishCdmEventsOptions
} from '@jarvis/shell-commons/dist/interface/v1/analytics/types'
import { StoreStateType } from '@jarvis/shell-commons/dist/interface/v1/store/types'
import useDeviceHttpProxy from '../hooks/useDeviceHttpProxy'
import {
  UiTheme,
  useMultiUiBrandContext
} from '@jarvis/react-setup-and-onboarding-addons'
import { Logger } from '../utils/Logger'

type ServiceLaunchOptions = {
  serviceOptions: { appSessionId: string }
}

export const ConfigContext = React.createContext({
  stack: null,
  navigation: null,
  localization: null,
  sessionContext: null,
  nextStage: null,
  publishCdmEvents: null,
  init: null,
  sendDHPRequest: null,
  closeServiceInstance: null,
  isHpx: false
})

export interface ConfigProviderProps {
  stack?: Stack
  localization?: { language: string; country: string; enabled?: boolean }
  children: unknown
  navigation?: History
  store?: StoreStateType
  analytics?: {
    publishCdmEvents: (
      events: DataValveCDMEvent[],
      options?: PublishCdmEventsOptions
    ) => void
  }
  serviceRouting?: {
    getServiceInstanceLaunchOptions: () => ServiceLaunchOptions
    closeServiceInstance: (arg0: unknown) => void
  }
  isHpx?: boolean
}

const ConfigProvider = (props: ConfigProviderProps) => {
  const {
    stack = Stack.pie,
    localization = { language: 'en', country: 'US' },
    children,
    store = null,
    analytics = null,
    serviceRouting = null
  } = props
  const { initializeDHP, sendDHPRequest } = useDeviceHttpProxy()
  const [sessionId, setSessionId] = useState(null)
  const country = localization.country.toUpperCase()
  const language = localization.language
  const enabled = localization.enabled
  const history = createBrowserHistory({
    basename: enabled ? `/${country.toLowerCase()}/${language}` : `/`
  })
  const [navigation] = useState(props.navigation ? props.navigation : history)
  const sessionContext = store?.state.onboarding.sessionContext
  const nextStage = store?.state.onboarding.nextStage
  const publishCdmEvents = analytics?.publishCdmEvents
  const { uiBrand } = useMultiUiBrandContext()

  const isHpx = useMemo(() => uiBrand === UiTheme.hpx, [uiBrand])

  const _getAppSessionId = async () => {
    try {
      const serviceLaunchOptions = await serviceRouting.getServiceInstanceLaunchOptions()
      const { serviceOptions } = serviceLaunchOptions
      if (serviceOptions?.appSessionId) {
        setSessionId(serviceOptions.appSessionId)
      }
    } catch (e) {
      Logger.error('Error on getting session id', e)
    }
    return
  }

  const init = async () => {
    await _getAppSessionId()
    await initializeDHP()
  }

  const closeServiceInstance = async (result = 'success', errorInfo = null) => {
    const xCorrelationId = sessionContext?.xCorrelationId
    const closeServiceInstanceOptions = {
      resultData: { result: result, xCorrelationId: xCorrelationId }
    }
    if (errorInfo !== null) {
      closeServiceInstanceOptions.resultData['errorInfo'] = errorInfo
    }
    try {
      await serviceRouting.closeServiceInstance(closeServiceInstanceOptions)
      Logger.info('App ended!')
    } catch (e) {
      Logger.error(
        'Error on trying close service instance, options:',
        closeServiceInstanceOptions,
        e
      )
    }
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const configState = {
    stack,
    navigation,
    localization,
    sessionContext,
    sessionId,
    nextStage,
    closeServiceInstance,
    publishCdmEvents,
    init,
    sendDHPRequest,
    isHpx
  }
  return (
    <ConfigContext.Provider value={configState}>
      {children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider
