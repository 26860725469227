import React, { useCallback, useEffect, useRef, useState } from 'react'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

const LaunchDarkly = ({ clientId, children }) => {
  const [content, setContent] = useState(<></>)
  const prevPropsRef = useRef<{ clientId: string }>()
  const prevProps = prevPropsRef.current

  useEffect(() => {
    prevPropsRef.current = { clientId }
  }, [clientId])

  const getLdConfig = useCallback(async () => {
    if (prevProps?.clientId !== clientId) {
      const LDProvider = await asyncWithLDProvider({
        clientSideID: clientId
      })
      setContent(<LDProvider>{children}</LDProvider>)
    }
  }, [children, clientId, prevProps?.clientId])

  useEffect(() => {
    getLdConfig()
  }, [getLdConfig])

  return content
}
export default LaunchDarkly
