import {
  getDeviceSetupHref,
  getSuppliesPrivateHref,
  getMediaConfigHref,
  getCalibrationStatusHref
} from '../utils/cdmHelper'
import { useCallback, useContext } from 'react'
import { ConfigContext } from '../store/ConfigContext'

export const DHP_ERROR_CODE = {
  invalidArgument: 'DHP00001',
  invalidSession: 'DHP00002',
  sessionNotActive: 'DHP00003',
  deviceOffline: 'DHP00004',
  requestTimedOut: 'DHP00005',
  cancelled: 'DHP00006',
  unknownError: 'DHP00007',
  dataValveError: 'DHP00008'
}

export const buildError = (error) => {
  const errorCode = DHP_ERROR_CODE[error.errorType] || 'DHP11111'
  return {
    errorCode: `ONBP_JWV_HWSC_${errorCode}`,
    errorDescription: `${error.errorType} - ${
      error.reason || 'Error calling DHP plugin (no reason)'
    }`,
    errorCodeLabel: errorCode
  }
}

const useCdm = () => {
  const { sessionId, sendDHPRequest: plugin } = useContext(ConfigContext)

  const _handleRequest = useCallback(
    async (payload) => {
      try {
        const response = await plugin(payload)
        if (response?.body?.data) {
          let isString = typeof response.body.data === 'string'
          if (isString) {
            response.body.data = response.body?.isBase64Encoded
              ? JSON.parse(atob(response.body.data))
              : JSON.parse(response.body.data)
          }
        }
        return response
      } catch (response) {
        let errorResponse = response
        if (response?.body?.data) {
          errorResponse = response.body.data
        }
        if (errorResponse?.errorType) {
          errorResponse['ONBP_ERROR'] = buildError(response)
        }
        throw errorResponse
      }
    },
    [plugin]
  )

  const fetch = useCallback(
    (href) => {
      const options = {
        path: href,
        sessionId: sessionId,
        method: 'GET',
        allowUserInteraction: true
      }
      return _handleRequest(options)
    },
    [_handleRequest, sessionId]
  )

  const fetchServicesDiscovery = useCallback(() => {
    return fetch('/cdm/servicesDiscovery')
  }, [fetch])
  const fetchActionPrintEngineSetup = useCallback(
    async (action) => {
      const href = getDeviceSetupHref(action)
      return fetch(href)
    },
    [fetch]
  )

  const fetchSupplies = useCallback(
    async (action) => {
      const href = getSuppliesPrivateHref(action)
      return fetch(href)
    },
    [fetch]
  )

  const fetchMediaConfig = useCallback(
    async (action) => {
      const href = getMediaConfigHref(action)
      return fetch(href)
    },
    [fetch]
  )

  const fetchCalibrationStatus = useCallback(
    async (action) => {
      const href = getCalibrationStatusHref(action)
      return fetch(href)
    },
    [fetch]
  )

  return {
    fetch,
    fetchServicesDiscovery,
    fetchActionPrintEngineSetup,
    fetchSupplies,
    fetchMediaConfig,
    fetchCalibrationStatus
  }
}

export default useCdm
