import { CDM_TREE_NAMES, QUERY_ENUMS, ANALYTICS } from '../store/Constants'

const CHECK_INFO_RESULT_MAP = {
  [CDM_TREE_NAMES.actionPrintEngineSetup]: 'actionPrintEngineSetupResult',
  [CDM_TREE_NAMES.calibrationStatus]: 'calibrationsResult',
  [CDM_TREE_NAMES.mediaConfig]: 'mediaInputsResult',
  [CDM_TREE_NAMES.suppliesPrivate]: 'suppliesResult'
}

export const getHref = (action, rel) => {
  try {
    const foundLink = action['links'].find((link) => link['rel'] === rel)
    return foundLink ? foundLink.href : ''
  } catch (e) {
    throw new Error(`An error occurred: ${e.message}`)
  }
}

const getService = (servicesDiscovery, resourceGun) => {
  try {
    return servicesDiscovery['services'].find(
      (service) => service['serviceGun'] === resourceGun
    )
  } catch (e) {
    return null
  }
}

// actionPrintEngineSetup
export const getDeviceSetupHref = (servicesDiscovery) => {
  return getHref(
    getService(servicesDiscovery, 'com.hp.cdm.service.deviceSetup.version.1'),
    'status'
  )
}

// Ink
export const getSuppliesPrivateHref = (servicesDiscovery) => {
  return getHref(
    getService(servicesDiscovery, 'com.hp.cdm.service.supply.version.1'),
    'suppliesPrivate'
  )
}

// Paper
export const getMediaConfigHref = (servicesDiscovery) => {
  return getHref(
    getService(servicesDiscovery, 'com.hp.cdm.service.media.version.1'),
    'configuration'
  )
}

// Calibration
export const getCalibrationStatusHref = (servicesDiscovery) => {
  return getHref(
    getService(servicesDiscovery, 'com.hp.cdm.service.calibration.version.2'),
    'statuses'
  )
}

export const getEnumFromQueryState = (state) =>
  ({
    [QUERY_ENUMS.COMPLETE]: 'success',
    [QUERY_ENUMS.FAILED]: 'unavailable'
  }[state] || 'truncated')

const getAvailableTreesKey = (trees) =>
  Object.keys(trees).filter((tree) => trees[tree])

export const buildStatuses = (trees, queryStatus) =>
  getAvailableTreesKey(trees).reduce(
    (statuses, tree) => ({
      ...statuses,
      [CHECK_INFO_RESULT_MAP[CDM_TREE_NAMES[tree]]]: getEnumFromQueryState(
        queryStatus[CDM_TREE_NAMES[tree]]
      )
    }),
    {}
  )

export const buildResourcesData = (trees) =>
  getAvailableTreesKey(trees).reduce(
    (resources, tree) => ({
      ...resources,
      ...trees[tree]
    }),
    {}
  )

export const buildCdmEventTrees = ({ trees, queryStatus }) => [
  {
    dateTime: new Date().toISOString(),
    eventDetailType: ANALYTICS.DEVICE_SETUP_CHECK_EVENT_DETAIL_TYPE,
    eventCategory: ANALYTICS.DEVICE_SETUP_CHECK_EVENT_CATEGORY,
    version: ANALYTICS.ENVELOPE_VERSION,
    eventDetail: {
      checkInfo: {
        checkSource: 'iot',
        ...buildStatuses(trees, queryStatus)
      },
      ...buildResourcesData(trees),
      version: ANALYTICS.DEVICE_SETUP_CHECK_VERSION
    }
  }
]
