import React, { useEffect } from 'react'
import { ShellProps } from '../src/types/shell'
import { Stack } from '@jarvis/web-stratus-client'
import projectNames from '../src/configs/projectNames'
import HardwareStatusCheck from '../src/HardwareStatusCheck'
import { Logger } from '../src/utils/Logger'
import { ThemeProvider } from '@veneer/theme'
import {
  getThemeProviderConfig,
  useMultiUiBrandContext
} from '@jarvis/react-setup-and-onboarding-addons'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  stack = Stack.pie,
  properties = { ldClientId: '60a54de5656c4a0f2ce0bc78' }
}) {
  const {
    v1: { navigation, store, localization, analytics, serviceRouting },
    v2: { authProvider }
  } = window.Shell as ShellProps
  const { uiBrand } = useMultiUiBrandContext()

  useEffect(() => {
    Logger.info('App rendered!')
  }, [])

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <ThemeProvider {...getThemeProviderConfig(uiBrand)}>
        <HardwareStatusCheck
          stack={stack}
          localization={localization}
          navigation={navigation}
          store={store}
          analytics={analytics}
          authProvider={authProvider}
          serviceRouting={serviceRouting}
          ldClientId={properties.ldClientId}
        />
      </ThemeProvider>
    </section>
  )
}
