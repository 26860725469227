import { useContext, useEffect, useState } from 'react'
import { ContentStackClient } from '../clients/ContentStackClient'
import { ConfigContext } from '../store/ConfigContext'
import { ContentType } from '../store/Constants'

export type PageData<PageDataType> = {
  pageData: PageDataType
}

export type ParsingFunction<
  EntryType extends Record<string, unknown>,
  PageDataType extends Record<string, unknown>
> = (data?: EntryType) => PageDataType

const useContentStack = <
  PageDataType extends Record<string, unknown>,
  EntryType extends Record<string, unknown>
>(
  content_type: ContentType,
  parsing_function: ParsingFunction<EntryType, PageDataType>
): PageData<PageDataType> => {
  const [pageData, setPageData] = useState(null)
  const [dataRetrieved, setDataRetrieved] = useState(false)
  const [currentlyFetching, setCurrentlyFetching] = useState(false)
  const {
    stack,
    localization: { country, language }
  } = useContext(ConfigContext)

  useEffect(() => {
    if (!currentlyFetching && !dataRetrieved) {
      const locale = `${language}-${country}`
      setCurrentlyFetching(true)
      const contentStackClient = new ContentStackClient(stack)
      contentStackClient
        .fetchContentType(content_type, locale.toLowerCase())
        .then((response) => {
          setDataRetrieved(true)
          setPageData(
            parsing_function(
              response.data.entry || (response.data.entries[0] as EntryType)
            )
          )
        })
        .catch(() => {
          setDataRetrieved(true)
        })
        .finally(() => {
          setCurrentlyFetching(false)
        })
    }
  }, [
    content_type,
    currentlyFetching,
    dataRetrieved,
    parsing_function,
    stack,
    country,
    language
  ])

  return { pageData }
}

export default useContentStack
