import { Stack } from '@jarvis/web-stratus-client'
import {
  contentStackStacks,
  ContentType,
  ContentStackStackType,
  CONTENT_STACK_API_KEY
} from '../store/Constants'
import axios from 'axios'

const getCredentials = (stack: Stack): ContentStackStackType => {
  switch (stack) {
    case Stack.stage:
      return contentStackStacks.stage
    case Stack.prod:
      return contentStackStacks.prod
    default:
      return contentStackStacks.pie
  }
}

export class ContentStackClient {
  baseUrl: string
  api_key: string
  delivery_token: string
  env_name: string
  constructor(stack = Stack.pie) {
    const { delivery_token, env_name } = getCredentials(stack)
    this.baseUrl = 'https://cdn.contentstack.io/v3/content_types'
    this.api_key = CONTENT_STACK_API_KEY
    this.delivery_token = delivery_token
    this.env_name = env_name
  }
  fetchContentType(content_type: ContentType, locale?: string) {
    const { entry_uid, content_type_uid, printer_sku } = content_type
    let url
    if (printer_sku) {
      url = `${this.baseUrl}/${content_type_uid}/entries?environment=${
        this.env_name
      }&query={"sku.select": "${printer_sku}"}&locale=${
        locale ?? 'en-us'
      }&include_fallback=true`
    } else {
      url = `${
        this.baseUrl
      }/${content_type_uid}/entries/${entry_uid}?environment=${
        this.env_name
      }&locale=${locale ?? 'en-us'}`
    }
    return axios.get(url, {
      headers: {
        api_key: this.api_key,
        access_token: this.delivery_token
      }
    })
  }
}
