import { useCallback, useContext, useEffect, useState } from 'react'
import useCdm from './useCdm'
import { ConfigContext } from '../store/ConfigContext'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { CDM_TREE_NAMES, QUERY_ENUMS } from '../store/Constants'
import { Logger } from '../utils/Logger'

const MAX_ALLOWED_ANALYTICS_ATTRIBUTES = 8

const LAUNCH_DARKLY_KEYS_MAP = {
  [CDM_TREE_NAMES.actionPrintEngineSetup]: 'deviceSetup',
  [CDM_TREE_NAMES.suppliesPrivate]: 'suppliesPrivate',
  [CDM_TREE_NAMES.mediaConfig]: 'mediaConfig',
  [CDM_TREE_NAMES.calibrationStatus]: 'calibrationStatus'
}

const parseActionPrintEngineSetup = (tree) => {
  return {
    actionPrintEngineSetup: {
      status: tree.actionPrintEngineSetup.status
    }
  }
}

const parseSuppliesPrivate = (tree) => {
  const supplies = []
  for (let supply of Object.values(tree)) {
    if (supply?.publicInformation) {
      supplies.push({
        group: supply.publicInformation?.group,
        slot: supply.publicInformation?.slot,
        supplyState: supply.publicInformation?.supplyState
      })
      if (supplies.length === MAX_ALLOWED_ANALYTICS_ATTRIBUTES) {
        break
      }
    }
  }

  return { supplies }
}

const parseMediaConfig = (tree) => {
  const mediaInputs = []
  for (let input of tree.inputs) {
    mediaInputs.push({
      mediaSourceId: input?.mediaSourceId,
      state: input?.state,
      stateReason: input?.stateReason
    })
    if (mediaInputs.length === MAX_ALLOWED_ANALYTICS_ATTRIBUTES) {
      break
    }
  }

  return { mediaInputs }
}
const parseCalibrationStatus = (tree) => {
  const calibrations = []
  for (let status of tree.calibrationStatuses) {
    calibrations.push({
      calibrationType: status?.calibrationType,
      calibrationStatus: status?.calibrationStatus,
      lastResultTimestamp: status?.lastResultTimeStamp
    })
    if (calibrations.length === MAX_ALLOWED_ANALYTICS_ATTRIBUTES) {
      break
    }
  }
  return { calibrations }
}

const useHwStatusCheckTrees = () => {
  const { sessionId } = useContext(ConfigContext)
  const [servicesDiscovery, setServicesDiscovery] = useState(null)
  const [queryStatus, setQueryStatus] = useState({})
  const [actionPrintEngineSetup, setActionPrintEngineSetup] = useState(null)
  const [suppliesPrivate, setSuppliesPrivate] = useState(null)
  const [mediaConfig, setMediaConfig] = useState(null)
  const [started, setStarted] = useState(false)
  const [calibrationStatus, setCalibrationStatus] = useState(null)
  const [fetchedDiscovery, setFetchedDiscovery] = useState(false)
  const {
    fetchServicesDiscovery,
    fetchActionPrintEngineSetup,
    fetchSupplies,
    fetchMediaConfig,
    fetchCalibrationStatus
  } = useCdm()

  const { onbpReleaseHwstatuscheckresources: ldResourcesCheck } = useFlags()

  const updateQueryStates = useCallback((key, val) => {
    setQueryStatus((prev) => ({ ...prev, [key]: val }))
  }, [])

  const fetchTree = useCallback(
    (tree) => {
      switch (tree) {
        case CDM_TREE_NAMES.actionPrintEngineSetup:
          return fetchActionPrintEngineSetup(servicesDiscovery).then(
            (response) => {
              setActionPrintEngineSetup(
                parseActionPrintEngineSetup(response?.body?.data)
              )
            }
          )
        case CDM_TREE_NAMES.suppliesPrivate:
          return fetchSupplies(servicesDiscovery).then((response) => {
            setSuppliesPrivate(parseSuppliesPrivate(response?.body?.data))
          })
        case CDM_TREE_NAMES.mediaConfig:
          return fetchMediaConfig(servicesDiscovery).then((response) => {
            setMediaConfig(parseMediaConfig(response?.body?.data))
          })
        case CDM_TREE_NAMES.calibrationStatus:
          return fetchCalibrationStatus(servicesDiscovery).then((response) => {
            setCalibrationStatus(parseCalibrationStatus(response?.body?.data))
          })
      }
    },
    [
      fetchActionPrintEngineSetup,
      fetchCalibrationStatus,
      fetchMediaConfig,
      fetchSupplies,
      servicesDiscovery
    ]
  )

  useEffect(() => {
    if (servicesDiscovery && !started) {
      for (const tree of Object.values(CDM_TREE_NAMES)) {
        if (ldResourcesCheck[LAUNCH_DARKLY_KEYS_MAP[tree]]) {
          Logger.info(`Fetching ${tree} service`)
          updateQueryStates(tree, QUERY_ENUMS.PENDING)
          fetchTree(tree)
            .then(() => {
              updateQueryStates(tree, QUERY_ENUMS.COMPLETE)
            })
            .catch((error) => {
              updateQueryStates(tree, QUERY_ENUMS.FAILED)
              Logger.error(`CDM request failed for ${tree}`, error)
            })
        }
      }
      setStarted(true)
    }
  }, [
    started,
    servicesDiscovery,
    fetchTree,
    ldResourcesCheck,
    updateQueryStates
  ])

  useEffect(() => {
    const fetchDiscovery = async () => {
      Logger.info('Fetching services')
      try {
        const { body } = await fetchServicesDiscovery()
        setServicesDiscovery(body.data)
      } catch (error) {
        Logger.error('Failed in discovering services', error)
        setServicesDiscovery(null)
      }
    }
    if (sessionId && !fetchedDiscovery && ldResourcesCheck?.enabled) {
      setFetchedDiscovery(true)
      fetchDiscovery()
    }
  }, [sessionId, fetchedDiscovery, ldResourcesCheck, fetchServicesDiscovery])

  return {
    actionPrintEngineSetup,
    suppliesPrivate,
    mediaConfig,
    calibrationStatus,
    servicesDiscovery,
    queryStatus,
    ldResourcesCheck
  }
}

export default useHwStatusCheckTrees
